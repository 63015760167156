/* General Styles for Heading */
.heading-container {
    text-align: center;
    padding: 20px;
    margin: 20px 0;
  }
  
  .heading-container h3 {
    font-size: 18px;
    color: #1eb2a6;
    margin-bottom: 10px;
  }
  
  .title {
    font-size: 32px;
    font-weight: bold;
    color: #333;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .heading-container h3 {
      font-size: 16px;
    }
  
    .title{
      font-size: 28px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .heading-container h3 {
      font-size: 14px;
    }
  
    .title{
      font-size: 24px;
    }
  }
  