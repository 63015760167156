/* about.css */

/* AboutMe Section */
/* AboutMe Section */
.aboutMe {
  padding: 80px 0;
  /* background-color: #f9f9f9; */
  text-align: center;
}

.aboutMe .container {
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1200px;
}

.aboutMe .header {
  margin-bottom: 30px;
}

.aboutMe .about-title {
  color: #0b9cd7;
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 10px;
}

.aboutMe .about-subtitle {
  color: #333;
  font-size: 20px;
  font-weight: 500;
}

.aboutMe .content {
  text-align: left;
  margin-top: 30px;
}

.aboutMe .about-highlight {
  background-color: #000000b0;
  color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.aboutMe .about-highlight h3 {
  font-size: 24px;
  margin-bottom: 10px;
}
.h33{
  color: white;
}

.aboutMe .about-services {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.aboutMe .service {
  flex: 1;
  margin: 0 10px;
  /* background-color: white; */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.aboutMe .service h4 {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}

.aboutMe .service p {
  color: #666;
}

.aboutMe .about-vision, .aboutMe .about-commitment {
  margin-top: 30px;
}

.aboutMe .about-vision h3, .aboutMe .about-commitment p {
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .aboutMe .about-highlight {
    background-color: #0000007e;
    color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
  }
  .aboutMe .about-services {
    flex-direction: column;
  }
  .aboutMe .service {
    margin-bottom: 20px;
  }
}


/* AboutHome Section */
.aboutHome {
  height: auto;
   
}

.aboutHome .container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.aboutHome .left, .aboutHome .right {
  flex: 1;
  min-width: 300px;
  margin: 10px;
}

.aboutHome .left img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

 
.aboutHome .items {
  margin-top: 30px;
}

.aboutHome .item {
  border-radius:10px ;
  background-color: #ffffff94;
  /* color: black; */
  margin-top: 20px;
  margin-right: 20px;
  padding: 20px;
  transition: 0.5s;
  display: flex;
  align-items: center;
}

.aboutHome .img {
  width: 70px;
  margin-right: 20px;
}

.aboutHome .item h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.aboutHome .item p {
  color: #7d7777;
}

.aboutHome .item:hover {
  background-color: #1eb2a6;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
}

.aboutHome .item:hover p {
  color: #fff;
}

@media screen and (max-width: 768px) {
  .aboutHome .container {
    flex-direction: column-reverse;
    padding: 0 10px;
  }
  .aboutHome .left, .aboutHome .right {
    width: 100%;
  }
}

/* Awrapper Section */
.awrapper {
  background-image: url("../../../public/images/awrapper.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 30vh;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.awrapper .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.awrapper .box {
  padding: 20px;
  flex: 1;
  min-width: 300px;
  display: flex;
  align-items: center;
}

.awrapper img {
  margin-right: 20px;
}

.awrapper h1 {
  font-size: 24px;
  margin-bottom: 5px;
}

.awrapper h3 {
  font-size: 16px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .awrapper {
    height: auto;
  }
  .awrapper .container {
    flex-direction: column;
  }
  .awrapper .box {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .awrapper img {
    margin-bottom: 10px;
    margin-right: 0;
  }
}


/* animation effect  */
/* Keyframes for fade-in animations */
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Initial state for animations */
.item {
  opacity: 0;
  transform: translateX(0);
  transition: opacity 0.5s, transform 1s;
}

.fade-in-left {
  animation: fadeInLeft 1s forwards;
}

.fade-in-right {
  animation: fadeInRight 1s forwards;
}

