/* Existing CSS styles here */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.msmeSection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  /* border-bottom: 1px solid #f71717; */
}

.msmeLogo {
  width: 100px;
  height: 100px;
  margin-right: 15px;
}

.msmeText {
  text-align: left;
}

.msmeText h2 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.msmeText span {
  font-size: 14px;
  color: #555;
}

/* Responsive styles */
@media (max-width: 768px) {
  .msmeSection {
    flex-direction: column;
    text-align: center;
  }

  .msmeLogo {
    margin-bottom: 10px;
  }

  .msmeText h2 {
    font-size: 16px;
  }

  .msmeText span {
    font-size: 12px;
  }
}

.flexSB {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flexSB1 {
  display: flex;
  justify-content: start;
  align-items: center;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logo h1 {
  font-size: 35px;
  font-weight: 900;
}

/* Header Section */
header {
  background-color: rgba(156, 176, 192, 0.281);
  margin: 0 30px;
  padding: 10px 0;
}

header .nav {
  display: flex !important;
}

header li {
  margin-right: 40px;
}

header ul {
  padding: 30px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

header ul li a {
  color: #000000;
  font-weight: 500;
  transition: 0.5s;
}

header ul li a:hover {
  color: #1eb2a6;
}

.start {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1eb2a6;
  padding: 15px 30px;
  color: #fff;
  clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
  text-align: center;
}

.start a {
  color: #fff;
  text-decoration: none;
}

.toggle {
  display: none;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 768px) {
  .start {
    display: none;
  }

  header {
    margin: 0;
    padding: 0 10px;
    background-color: #1eb2a630;
    position: relative;
  }

  header ul.flexSB {
    display: none;
  }

  header ul.mobile-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  }

  header ul.mobile-nav.open {
    display: flex;
    z-index: 10;
    opacity: 1;
    transform: translateY(0);
  } 
  header ul li {
    margin-bottom: 20px;
  }

  header ul li a {
    color: #fff;
  }

  .toggle {
    display: block;
    background: none;
    color: #fff;
    font-size: 30px;
    position: absolute;
    right: 0px;
    top: -35px;
    cursor: pointer;
  }

  .mobile-nav {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    /* z-index: -1; */
    background-color: #1eb2a6;
  }
}

.logoContainer img {
  width: 80px;
  height: 80px;
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .logoContainer img {
    width: 60px;
    height: 60px;
  }

  .logo h1 {
    font-size: 24px;
  }
}

/* Social Icons */
.social-icons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.social-icons a {
  color: #fff;
  font-size: 20px;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #1eb2a6;
}

@media screen and (max-width: 768px) {
  .social-icons {
    margin-top: 10px;
  }
}