/* General Footer Styles */
footer {
  background-color: #000000;
  text-align: center;
  margin-top: 50px;
  padding: 50px 20px;
}

footer .container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 30px;
  align-items: start;
}

footer .box {
  text-align: left;
}

footer .logo span {
  color: #1eb2a6;
  font-size: 14px;
}

footer .logo p {
  color: grey;
  margin: 30px 0 15px 0;
}

footer .logo .icon {
  background-color: #1eb2a6;
  color: white;
  margin-right: 10px;
}

footer h1 {
  font-size: 24px;
  color: #1eb2a6;
  margin-bottom: 10px;
}

footer h3 {
  margin-bottom: 40px;
  font-weight: 500;
  color: #ffffffb2;
}
.msmeText{
  color: white;
}
footer ul {
  list-style: none;
  padding: 0;
}

footer ul li {
  margin-bottom: 20px;
  font-size: 14px;
  color: grey;
}

footer ul li i {
  margin-right: 10px;
  color: #1eb2a6;
}

footer .link li::before {
  content: "⟶";
  color: #1eb2a6;
  margin-right: 5px;
}

footer .items {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

footer .items img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
  object-fit: cover;
}

footer .items span,
footer .items i {
  font-size: 12px;
  color: #1eb2a6;
  margin-right: 5px;
  text-transform: capitalize;
}

footer h4 {
  font-weight: 400;
  margin-top: 5px;
}

footer .last ul li {
  display: flex;
  align-items: center;
}

footer .msmeSection {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

footer .msmeLogo {
  width: 100px;
  height: auto;
  margin-right: 20px;
}

footer .msmeText h2 {
  font-size: 16px;
  margin-bottom: 5px;
}

footer .msmeText span {
  font-size: 14px;
  color: grey;
}

.legal {
  text-align: center;
  padding: 20px 0;
  color: #ffffffb2;
  background-color: #000000;
}

.legal p {
  font-size: 15px;
  margin: 0;
}

.legal i {
  color: #1eb2a6;
}

/* Social Icons */
.social-icons {
  margin-top: 20px;
}

.social-icons a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-right: 10px;
  text-align: center;
  border-radius: 50%;
  background-color: #1eb2a6;
  color: #fff;
  transition: background-color 0.3s;
}

.social-icons a:hover {
  /* background-color: #17a2b8; */
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  footer .container {
    grid-template-columns: 1fr;
  }

  footer .box,
  footer .last {
    text-align: center;
  }

  footer .logo p {
    margin: 15px 0;
  }

  footer .last ul {
    justify-content: center;
  }

  .legal {
    padding: 10px 0;
  }

  footer .msmeSection {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  footer .msmeLogo {
    margin: 0 0 10px 0;
  }
  .foot{
    margin-top: -150px;
  }
}

