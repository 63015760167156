/* coursesCard */
.coursesCard {
  padding: 50px 0;
}


.coursesCard .items {
  background-color: #fff;
  padding: 30px;
  text-align: center;
  margin-bottom: 20px; /* Added for spacing between cards on smaller screens */
}

.coursesCard .img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #1eb2a6;
  padding: 15px;
  margin: 0 auto; /* Center the image */
}

.coursesCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.coursesCard .text {
  margin-left: 15px;
  text-align: left;
}

.coursesCard .text h1 {
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
}

.coursesCard .rate {
  margin: 20px 0;
  color: #1eb2a6;
}

.coursesCard .rate i {
  font-size: 13px;
  margin-right: 5px;
}

.coursesCard .details .dimg img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
}

.coursesCard .details .box {
  display: flex;
  align-items: center;
  color: grey;
}

.coursesCard .details span {
  color: #1eb2a6;
  font-weight: 500;
  font-size: 14px;
}

.coursesCard .price {
  margin: 30px 0;
  background-color: #f8f8f8;
  padding: 10px;
}

.coursesCard h3 {
  font-weight: 500;
  color: #1eb2a6;
}

/* online */
.online {
  /* z-index: 100; */
  text-align: center;
}

.online .box {
  box-shadow:0 5px 25px -2px rgb(0 0 0 / 25%);
  border-radius: 20px;
  background-color: #fff;
  padding: 5px;
  transition: 0.5s;
  margin-bottom: 20px; /* Added for spacing between cards on smaller screens */
}

.online .img {
  width: 60px;
  height: 60px;
  margin: auto;
  position: relative;
}

.online img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.online h1 {
  font-weight: 500;
  font-size: 20px;
  margin: 20px 0;
  line-height: 30px;
}

.online span {
  background-color: #f8f8f8;
  padding: 15px 20px;
  font-weight: 500;
  font-size: 15px;
  max-width: 160px;
  color: #1eb2a6;
  border-radius: 5px;
}
.lili{
  background-color: #f8f8f8;
  padding: 15px 20px;
  margin-top: -10px;
  font-weight: 500;
  font-size: 15px;
  max-width: 120px;
  color: #1eb2a6;
  border-radius: 5px;
}
.aaa{
  display: flex;
  justify-content: center;
  align-items: center;
}

.online .box .show {
  opacity: 0;
}

.online .box:hover {
  background-color: #1eb2a6;
  border-radius: 5px;
  cursor: pointer;
}

.online .box:hover .show {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.online .box:hover h1 {
  color: #fff;
}
.intern{
  color: #1d7a63;
  font-size: 35px;
  font-weight: 600;

  display: flex;
  justify-content: center;
  align-items: center;
}  
.intern1{
  /* color: #1eb2a6; */
  font-size: 30px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 768px) {
  .coursesCard .text {
    margin-left: 0;
    text-align: center;
  }

  .coursesCard .text h1 {
    font-size: 24px;
    line-height: 35px;
  }

  .coursesCard .details .dimg img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .coursesCard .details span {
    font-size: 12px;
  }

  .coursesCard .price {
    margin: 20px 0;
    padding: 10px;
  }

  .online .box {
    padding: 20px 15px;
  }
  .intern{
    color: #1d7a63;
    font-size: 35px;
    font-weight: 600;
  }  
  .intern1{
    /* color: #1eb2a6; */
    font-size: 15px;
    font-weight: 500;
  }

  .online h1 {
    font-size: 18px;
    margin: 15px 0;
    line-height: 25px;
  }

  .online span {
    padding: 5px 15px;
    font-size: 14px;
  }
}


@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Initial state for animations */
 


/* Media queries for small screens */
/* @media (max-width: 768px) {
  .helo {
    opacity: 0;
    transform: translateX(0);
    transition: opacity 0.5s, transform 1s;
  }
  .fade-in-left1 {
    animation: fadeInLeft 1s forwards;
  }

  .fade-in-right1 {
    animation: fadeInRight 1s forwards;
}
} */

/* Existing CSS */

/* Categories */
.categories {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.category-button {
  background-color: #f8f8f8;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  font-weight: 500;
  font-size: 15px;
  color: #1eb2a6;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.category-button.active,
.category-button:hover {
  background-color: #1eb2a6;
  color: #fff;
}

/* Responsive CSS */
@media screen and (max-width: 768px) {
  .categories {
    flex-wrap: wrap;
  }

  .category-button {
    margin: 5px 10px;
    font-size: 14px;
  }
}

/* Other existing styles */
