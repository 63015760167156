.hero {
  background-image: url("../../../../public/images/whattt.jpg");
  background-size: cover;
 
  /* background-attachment: fixed; */
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  padding-top: 20%;
  padding-right: 50px;
  color: #fff;
}
.hero .container {
  /* background-color: rgba(0, 0, 0, 0.441); */
  margin-top: 40px;
  padding-right: 300px;

  z-index: 1;
  width: 100%;
}

.hero .row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.hero #heading h3 {
  color: #fff;
}

.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}

.button {
  margin-top: 20px;
}

.button a {
  text-decoration: none;
}

.primary-btn {
  background-color: #ff7f50;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.primary-btn:hover {
  background-color: #ff4500;
}
.slogan{
  color: rgb(255, 255, 255);
  /* background-color: rgba(0, 0, 0, 0.247); */
  font-size: 20px;
  font-weight: 600;
  padding: 5px;
  border-radius: 10px;
}

@media screen and (max-width: 1024px) {
  .hero {
    padding-top: 25%;
    
     height: auto;
  }
  .skill{
    line-height: 5; /* Adjusts the height of the line break */
    margin: 10px 0; /* Adds margin above and below the line break */
  }
}
.slogan{
  color: rgba(0, 0, 0, 0.842);
  /* background-color: rgba(0, 0, 0, 0.247); */
  /* padding: 5px; */
  border-radius: 10px;
}
.her{
  font-weight: 700;

  font-size: 34px;
  /* padding: 20px; */
  color: rgba(0, 0, 0, 0.772);
  /* background-color: rgba(0, 0, 0, 0.247); */
  border-radius: 10px;
}

.her1{
  /* background-color: rgba(0, 0, 0, 0.247); */
  border-radius: 10px;
  font-weight: 700;
  color: rgb(0, 0, 0);
  font-size: 28px;
  padding: 0px;
}
@media screen and (max-width: 768px) {
  .hero .container {
    
 
    padding-right: 0px;
  
    
  }
  
  .hero {
    background-position: center;
    padding-top: 30%;
    padding-right: 10px;
    height: auto;
  }
  .slogan{
    color: rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, 0.247);
    border-radius: 10px;
    padding: 5px;
  }

  .margin {
    height: 30vh;
  }

  .hero .row {
    width: 100%;
  }
 
.her{
  font-size: 25px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.247);
  border-radius: 10px;
}

.her1{
  background-color: rgba(0, 0, 0, 0.247);
  border-radius: 10px;
  font-weight: 700;
  color: white;
  font-size: 24px;
  padding: 20px;
}
}

@media screen and (max-width: 480px) {
  .hero .container {
    
 
    padding-right: 0px;
  
    
  }
  .hero {
    padding-top: 65%;
    /* margin-top: 60px; */
    /* padding-bottom: 120px; */
    /* margin-bottom: 40px; */
    padding-right: 5px;
    height: auto;
  }
  .her{
    color: white;
    font-size: 25px;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.247);
    border-radius: 10px;
  }

  .her1{
    background-color: rgba(0, 0, 0, 0.247);
    border-radius: 10px;
    font-weight: 700;
    color: white;
    font-size: 24px;
    padding: 20px;
  }

  .margin {
    height: 50vh;
  }
  .hero .row {
    width: 100%;
    height: 60vh;
  }

  .primary-btn {
    padding: 8px 16px;
  }
}

.faqss{
  
  display: flex;
  align-items: center;
  justify-content: center;
}
.faqss button:hover{
  background-color:aqua;
  color: white;
}
.faqss button:active {
  background-color: rgb(0, 247, 255);
}