.services {
  padding: 0px 20px;
  /* background-color: #f7f7f7; */
}

.services-title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.service-card {
  background-color: #ffffffbe;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-icon {
  font-size: 3rem;
  margin-bottom: 15px;
}

.service-name {
  font-size: 1.2rem;
  color: #555;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .service-card {
    opacity: 0;
    transform: translateX(0);
    transition: opacity 0.5s, transform 1s;
  }

  .fade-in-left {
    animation: fadeInLeft 1s forwards;
  }

  .fade-in-right {
    animation: fadeInRight 1s forwards;
  }
  
  .services-title {
    font-size: 2rem;
  }

  .service-card {
    padding: 20px;
  }

  .service-name {
    font-size: 1rem;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
