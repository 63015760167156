/* Add or update these styles in projects.css */

.projects {
  padding: 2rem 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.projects-title {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
}

.project-card {
  background-color: #fff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.project-image-container {
  overflow: hidden;
  border-radius: 8px;
}

.project-image {
  width: 100%;
  height: auto;
  display: block;
}

.project-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project-name {
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0.5rem 0;
}

.project-description {
  color: black;
  font-size: 15px;
  margin-bottom: 0.5rem;
}

.read-more {
  display: block;
  text-align: center;
  margin-top: 0.5rem;
  color: #007bff;
  font-weight: bold;
  text-decoration: none;
}

@media (max-width: 768px) {

  
  .project-card {
    opacity: 0; /* Start hidden */
    transform: translateY(-20px); /* Start slightly above */
    transition: opacity 1s ease-out, transform 1s ease-out;
  }
  
  .project-card.visible {
    opacity: 1; /* Fade in */
    transform: translateY(0); /* Move to original position */
  }
}
/* Add these styles to projects.css */

